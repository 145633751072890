@use 'variables' as variables;

@mixin texts {
  h1,
  h2,
  h3,
  h4,
  p {
    color: var(--black);
    font-weight: normal;
    margin: 0;
  }
  h1 {
    font-size: 1.75rem; // 28px
    font-weight: lighter;
  }
  h2 {
    font-size: 1.125rem; // 18px
    font-weight: lighter;
  }
  h3 {
    font-size: 1rem; // 16px
  }
  h4 {
    font-size: 0.875rem;
    font-weight: bold;
  }
  a,
  div,
  footer,
  header,
  main,
  p,
  section,
  span {
    font-size: 0.875rem;
    line-height: 1.125;
  }
  .sub {
    font-weight: lighter;
    color: var(--grey);
  }

  .wysiwyg * {
    color: unset;
    font-size: unset;
    font-weight: unset;
    line-height: unset;
  }

  @media only screen and (min-width: variables.$mq-gt-laptop) {
    h1 {
      font-size: 2.125rem; // 34px
    }
    h2 {
      font-size: 1.375rem; // 22px
      font-weight: lighter;
    }
    h3 {
      font-size: 1.125rem; // 18px
    }
    h4 {
      font-size: 1rem;
      font-weight: bold;
    }
    a,
    div,
    footer,
    header,
    main,
    p,
    section,
    span {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}
