@mixin icon-only-button {
  button.mdc-button.icon-only .mat-icon {
    margin: 0;
  }
}

@mixin loading-button {
  button.loading,
  button.loading,
  button.loading {
    .mdc-button__label,
    .mat-icon {
      visibility: hidden;
    }
    .mat-mdc-progress-spinner {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
