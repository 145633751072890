@use '@angular/material' as mat;
@use 'angular-material' as am;

@mixin colors {
  --black: #303030;
  --blue: #{mat.get-theme-color(am.$engine-theme, primary)};
  --green: #51ae53;
  --grey: #6f6f6f;
  --orange: #ffac5d;
  --red: #de4848;
  --violet: #af5bc6;
  --white: white;
  --mild-grey: #cccccc;
  --lighter-grey: #f4f4f4;
  --yellow: #f7d210;
  --amex-blue: #286cb4;
  --error: #{mat.get-theme-color(am.$engine-theme, error)};
  --primary: #{mat.get-theme-color(am.$engine-theme, primary)};

  --light-blue: color-mix(in srgb, var(--blue) 20%, white);
  --light-green: color-mix(in srgb, var(--green) 20%, white);
  --light-orange: color-mix(in srgb, var(--orange) 20%, white);
  --light-red: color-mix(in srgb, var(--red) 20%, white);
  --light-violet: color-mix(in srgb, var(--violet) 20%, white);
  --light-yellow: color-mix(in srgb, var(--yellow) 20%, white);
  --light-grey: color-mix(in srgb, var(--grey) 20%, white);

  --light-primary: color-mix(in srgb, var(--primary) 20%, white);
}
