@use 'angular-material' as am;
@use 'colors' as colors;
@use 'texts' as texts;
@use 'variables' as variables;
@use 'mixins' as mixins;
@use 'buttons' as buttons;

html {
  @include colors.colors();
  @include texts.texts();
  @include variables.variables();
  @include mixins.drag-and-drop;
  @include buttons.loading-button;
  @include buttons.icon-only-button;
}

html,
body {
  height: 100%;
}

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  margin: 0;

  a {
    color: inherit;
  }
}
