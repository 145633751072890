$mq-laptop: 1600px;
$mq-gt-laptop: 1601px;

@mixin variables {
  --calendar-day-header-height: 7.5rem;
  --gap: 1rem;
  --global-status-border-width: 0.625rem;
  --header-height: 4rem;
  --navbar-height: 3.063rem; // 49px
  --padding: 0.75rem;

  @media only screen and (min-width: $mq-gt-laptop) {
    --calendar-day-header-height: 10.25rem;
    --gap: 2rem;
    --padding: 1rem;
  }
}
