@use '@angular/material' as mat;

@include mat.core();

$engine-theme: mat.define-theme(
  (
    color: (
      primary: mat.$azure-palette,
    ),
  )
);

html {
  @include mat.all-component-themes($engine-theme);
  --mdc-chip-elevated-container-color: #{mat.get-theme-color($engine-theme, background)};
  --mat-dialog-container-max-width: 80vw;
  --mdc-fab-container-color: #{mat.get-theme-color($engine-theme, primary)};
  --mat-fab-foreground-color: white;
  --mdc-fab-small-container-color: #{mat.get-theme-color($engine-theme, primary)};
  --mat-fab-small-foreground-color: white;
}

.inverted {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: #{mat.get-theme-color($engine-theme, primary)};
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: #{mat.get-theme-color($engine-theme, primary)};
}

.mat-mdc-tooltip.mdc-tooltip--multiline {
  white-space: pre-line;
}
