@use '@angular/material' as mat;

@mixin card-border($borderWidth: 1px 1px 1px 5px, $elevation: 2, $borderRadius: 0.5rem) {
  @include mat.elevation($elevation);
  background-color: var(--white);
  border-width: $borderWidth;
  border-color: var(--mild-grey) var(--mild-grey) var(--mild-grey) var(--black);
  border-radius: $borderRadius;
  border-style: solid;
  overflow: hidden;
  user-select: none;
}

@mixin drag-and-drop {
  .cdk-drag-placeholder {
    display: none;
    height: 0;
    width: 0;
    opacity: 0;
  }
  .cdk-drag:not(.cdk-drag-disabled) {
    cursor: move;
  }
  .cdk-drag-preview {
    .alternatives {
      display: none;
    }
  }

  .cdk-drop-list-dragging .cdk-drag {
    transform: none !important;
  }
}

@mixin line-clamp($lines) {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

@mixin min-lines($linesNumber, $lineHeight) {
  line-height: $lineHeight;
  height: calc($lineHeight * $linesNumber);
}

@mixin drop-cover($text) {
  &.cdk-drop-list-dragging {
    &:before {
      align-items: center;
      background: var(--primary);
      inset: 0;
      color: var(--white);
      content: $text;
      display: flex;
      font-size: 1.875rem;
      justify-content: center;
      opacity: 0.9;
      position: absolute;
      flex-direction: row;
    }
  }
}

@mixin calendar-card-layout {
  @include card-border(1px 1px 1px 0px);
  display: grid;
  grid-template-areas:
    'status header'
    'status body'
    'status footer';
  grid-template-columns: auto minmax(0, 1fr);
  engine-booking-experience-global-status {
    grid-area: status;
  }
  engine-booking-experience-card-header {
    grid-area: header;
  }
  engine-experience-card-body {
    grid-area: body;
  }
  engine-booking-experience-card-footer {
    grid-area: footer;
  }
}

@mixin global-status-and-services-colors {
  &.booked-independently {
    border-color: var(--light-grey);
    border-style: groove;
    background-color: var(--lighter-grey);
  }
  &.booked-independently-confirmed {
    border-style: groove;
  }
  &.confirmed,
  &.booked-independently-confirmed {
    border-color: var(--green);
    background-color: var(--light-green);
  }
  &.error,
  &.non-available {
    border-color: var(--red);
    background-color: var(--light-red);
  }
  &.booking-desk,
  &.booking-desk-non-available,
  &.booking-desk-check-manually,
  &.booking-desk-available,
  &.booking-desk-being-cancelled {
    border-color: var(--violet);
    background-color: var(--light-violet);
  }
  &.check-manually {
    border-color: var(--orange);
    background-color: var(--light-orange);
  }
  &.available {
    border-color: var(--blue);
    background-color: var(--light-blue);
  }
  &.cancelled,
  &.hidden {
    border-color: var(--grey);
    background-color: var(--light-grey);
  }
}
